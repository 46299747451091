<template>
  <div class="container">
    <div class="header">
      <h2>大区-省份管理</h2>
      <el-button type="primary" @click="showAddRegionDialog">新增大区</el-button>
    </div>

    <div class="content">
      <!-- 左侧大区列表 -->
      <div class="left-panel">
        <el-card class="region-list">
          <div slot="header" class="clearfix">
            <span>大区列表</span>
          </div>
          <el-table
              :data="regions"
              highlight-current-row
              @current-change="handleRegionSelect"
              style="width: 100%">
            <el-table-column
                prop="name"
                label="大区名称">
            </el-table-column>
            <el-table-column
                label="操作"
                width="80">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="danger"
                    @click.native.stop="deleteRegion(scope.row.id)"
                    v-if="scope.row.id !== currentRegionId">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>

      <!-- 右侧省份列表 -->
      <div class="right-panel">
        <el-card class="province-list">
          <div slot="header" class="clearfix">
            <span>省份列表</span>
            <el-button
                style="float: right; padding: 3px 0"
                type="text"
                @click="saveProvinceRelations">保存
            </el-button>
          </div>
          <el-table
              :data="provinces"
              style="width: 100%">
            <el-table-column
                prop="name"
                label="省份名称">
            </el-table-column>
            <el-table-column
                prop="code"
                label="省份代码"
                width="120">
            </el-table-column>
            <el-table-column
                label="是否关联"
                width="100">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="handleCheckChange(scope.row)"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <!-- 新增大区对话框 -->
    <el-dialog title="新增大区" :visible.sync="addRegionDialogVisible" width="30%">
      <el-form :model="newRegionForm" :rules="rules" ref="newRegionForm">
        <el-form-item label="大区名称" prop="name">
          <el-input v-model="newRegionForm.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRegionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRegion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';

export default {
  name: 'RegionProvinceManage',
  data() {
    return {
      regions: [],
      provinces: [],
      currentRegionId: '',
      regionProvinceRelations: [],
      addRegionDialogVisible: false,
      newRegionForm: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入大区名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      await this.fetchRegions()
      await this.fetchProvinces()
    },

    async fetchRegions() {
      try {
        await post('/wx/wxGetRegions' ,null, response =>{
          if(response && response.data && response.data.status === 'success'){
            this.regions = response.data.entity;
          }
        })
      } catch (error) {
        this.$message.error('获取大区列表失败')
      }
    },

    async fetchProvinces() {
      try {
        await post('/wx/wxGetProvince', null, response=>{
          if(response && response.data && response.data.status === 'success'){
            this.provinces = response.data.entity.map(province => ({
              ...province,
              checked: false
            }));
          }
        })
      } catch (error) {
        this.$message.error('获取省份列表失败')
      }
    },

    async fetchRegionProvinceRelations(regionId) {
      try {
        await post('/wx/regionProvince/listByRegion', { "regionId":regionId }, response=>{
          if(response && response.data && response.data.status === 'success'){
            this.regionProvinceRelations = response.data.entity;
            this.updateProvinceCheckedStatus();
          }
        })
      } catch (error) {
        this.$message.error('获取大区-省份关系失败')
      }
    },

    updateProvinceCheckedStatus() {
      this.provinces.forEach(province => {
        province.checked = this.regionProvinceRelations.some(
            relation => relation.provinceId === province.id
        )
      })
    },

    handleRegionSelect(region) {
      if (region) {
        this.currentRegionId = region.id
        this.fetchRegionProvinceRelations(region.id)
      }
    },

    handleCheckChange(province) {
      if (province.checked) {
        // 添加关系
        this.regionProvinceRelations.push({
          regionId: this.currentRegionId,
          provinceId: province.id
        })
      } else {
        // 移除关系
        const index = this.regionProvinceRelations.findIndex(
            relation => relation.provinceId === province.id
        )
        if (index !== -1) {
          this.regionProvinceRelations.splice(index, 1)
        }
      }
    },

    async saveProvinceRelations() {
      if (!this.currentRegionId) {
        this.$message.warning('请先选择一个大区')
        return
      }

      try {
        await post('/wx/regionProvince/saveBatch', {
          regionId: this.currentRegionId,
          provinceIds: this.regionProvinceRelations.map(r => r.provinceId)
        }, response=>{
          if(response && response.data && response.data.status === 'success'){
            this.$message.success('保存成功')
          }
        })
      } catch (error) {
        this.$message.error('保存失败')
      }
    },

    showAddRegionDialog() {
      this.newRegionForm = { name: '' }
      this.addRegionDialogVisible = true
      this.$nextTick(() => {
        this.$refs.newRegionForm.clearValidate()
      })
    },

    async addRegion() {
      this.$refs.newRegionForm.validate(async (valid) => {
        if (valid) {
          try {
            console.log('this.newRegionForm ...', this.newRegionForm);
            await post('/wx/region/save', this.newRegionForm, response=>{
              if(response && response.data && response.data.status === 'success'){
                this.$message.success('新增成功')
                this.addRegionDialogVisible = false
              }
            })
            await this.fetchRegions();
          } catch (error) {
            this.$message.error('新增失败')
          }
        }
      })
    },

    deleteRegion(regionId) {
      this.$confirm('确定要删除这个大区吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await post('/wx/region/delete', {id: regionId}, response => {
            this.deleteRegionConsumer(response, regionId)
          });

        } catch (error) {
          this.$message.error('删除失败')
        }
      }).catch(() => {
        // 用户取消
      })
    },

    async deleteRegionConsumer(response, regionId){
      if(response && response.data && response.data.status === 'success'){
        this.$message.success('删除成功')
        await this.fetchRegions()
        if (this.currentRegionId === regionId) {
          this.currentRegionId = ''
          this.regionProvinceRelations = []
          this.updateProvinceCheckedStatus()
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.left-panel {
  width: 300px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.region-list, .province-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* 添加表格容器样式 */
.region-list >>> .el-card__body,
.province-list >>> .el-card__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
}

/* 表格样式 */
.region-list >>> .el-table,
.province-list >>> .el-table {
  flex: 1;
  overflow: auto;
}

/* 表头固定 */
.region-list >>> .el-table__header-wrapper,
.province-list >>> .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 2;
}
.el-table {
  height: calc(100% - 60px);
}
</style>